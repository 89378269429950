/** @jsx jsx */
import DonationHeader from 'components/DonationHeader'
import DonationOtherMethods from 'components/DonationOtherMethods'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import {graphql, PageProps} from 'gatsby'
import {Fragment} from 'react'
import {jsx} from 'theme-ui'
import {DonationQuery} from '../graphqlTypes'
import {PageContext} from 'gatsbyTypes'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

interface IDonationPage extends PageProps<DonationQuery, PageContext> {}

const DonationPage: React.FC<IDonationPage> = ({data, pageContext}) => {
  useAutoSelectLanguage(pageContext)
  return (
    <Layout
      sx={{
        position: 'relative',
        bg: '#F5F5F5',
      }}
      navMobileColor="#E5E5E5"
    >
      <SEO
        title="Faites un don. Ayez un impact. Changez leurs vies."
        description=""
      />
      <DonationHeader data={data} />

      <DonationOtherMethods
        title="Autres méthodes de paiement"
        interacBankTitle="Transfert par Interac (Canada seulement)"
        interacBankNote={
          <Fragment>
            Utilisez les courriels suivants - dans le cas où un mot de passe est
            requis, veuillez entrer la question:&nbsp;
            <b
              sx={{
                color: '#000',
              }}
            >
              donate
            </b> et la réponse:&nbsp;
            <b
              sx={{
                color: '#000',
              }}
            >
              ummaty
            </b>
          </Fragment>
        }
        interacBankItems={[
          {
            name: 'Sadaqa - Besoins les plus urgents',
            email: 'donate@ummaty.org',
          },
          {
            name: 'Zakat',
            email: 'zakat@ummaty.org',
          },
          {
            name: 'Zakat al-Fitr',
            email: 'zakat-alfitr@ummaty.org',
          },
        ]}
        bankWireTransferTitle="Transfert au compte bancaire"
        bankWireTransferItems={[
          {
            label: 'Nom de la banque',
            content: 'BMO Bank of Montreal',
          },
          {
            label: 'Adresse de la succursale',
            content: '6-90 Elgin street, Ottawa ON, K1P 0C6, Canada',
          },
          {
            label: 'Nom du compte',
            content: 'FONDATION UMMATY',
          },
          {
            label: 'Code SWIFT',
            content: 'BOFMCAM2',
          },
          {
            label: 'ABA (Numéro de routage)',
            content: '026005092',
          },
          {
            label: 'Code CC',
            content: 'CC000124866',
          },
          {
            label: 'Numéro du Compte',
            content: '24866-001-1994226',
          },
        ]}
        chequeTitle="Chèque"
        chequeContent={`
              <h3>Veuillez libeller votre chèque à l'ordre de:</h3>
              <p>Fondation UMMATY</p>
              <h3>Envoyez le chèque à l’adresse suivante:</h3>
              <p>Fondation UMMATY,<br />
              Suite 300 - 6020 rue Jean-Talon E Montréal (Québec),<br />
              H1S3B1 Canada</p>
            `}
      />
    </Layout>
  )
}
export default DonationPage

export const pageQuery = graphql`
  query DonationFR {
    ...DonationHeader
  }
`
